<template>
    <v-app>
        <DashboardCoreView />
    </v-app>
</template>
<script>
export default {
    name: "DashboardIndex",
    components: {
        DashboardCoreView: () => import("./View"),
    },
    data: () => ({}),
};
</script>
